<template>
  <div>
    <main class="content-500">
      <section id="eventDetailsHeader">
        <div class="t-right">
          <div class="label button bgcolor-blue color-white">
            {{ eventDetails.categories }}
          </div>
        </div>
        <img class="titleImage" :src="config.projectURL + 'assets/images/events/'+eventDetails.image+'.jpg'">
        <div class="t-left">
          <h3 style="font-size: 1.6em; padding: 16px 16px 16px 0">
            {{ eventDetails.title }}
          </h3>
<!--          <h4 class="f-bold color-blue">-->
<!--            {{ eventDetails.subTitle }}-->
<!--          </h4>-->
        </div>
        <div class="detailswrapper grid-12 t-center">
          <div class="col-sp-4 row-sp-12 detailbox ">
            <h4>{{ startDate }}</h4>
            <h5>{{ startDay }}</h5>
          </div>
          <div class="col-sp-4 row-sp-12 detailbox ">
            <h4>{{ startTime }} Uhr</h4>
            <h5>Beginn</h5>
          </div>
          <div class="col-sp-4 row-sp-12 detailbox ">
            <h4>{{ cheapestPrice }} €</h4>
            <h5>VVK: {{ vvkEnd }} Uhr</h5>
          </div>
          <div class="col-sp-12 row-sp-12">
            <a target="_blank" :href="'https://www.google.com/maps?q='+eventDetails.location" class="col-sp-12 row-sp-12 detailboxFlex">
              <svg width="50px" viewBox="0 0 91 91" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 91 91"><path d="M66.9 41.8c0-11.3-9.1-20.4-20.4-20.4-11.3 0-20.4 9.1-20.4 20.4 0 11.3 20.4 32.4 20.4 32.4s20.4-21.1 20.4-32.4zM37 41.4c0-5.2 4.3-9.5 9.5-9.5s9.5 4.2 9.5 9.5c0 5.2-4.2 9.5-9.5 9.5-5.2 0-9.5-4.3-9.5-9.5z" fill="#ffffff" class="fill-000000" /></svg>
              <p class="color-white">
                {{ eventDetails.location }}
              </p>
            </a>
          </div>
          <div class="col-sp-12 row-sp-12 clubzettel">
            <div class="grid-12">
              <div class="col-sp-6 row-sp-12 box">
                <div class="flex">

                  <div v-if="eventDetails.muttizettel" class="accept">
                    <span>👍🏼</span>
                  </div>
                  <div v-else class="reject">
                    <span>👎🏼</span>
                  </div>

                  <span>Muttizettel</span>
                </div>
              </div>
              <div class="col-sp-6 row-sp-12 box">
                <div class="flex">
                  <div v-if="eventDetails.abendkasse" class="accept" >
                    <span>👍🏼</span>
                  </div>
                  <div v-else class="reject">
                    <span>👎🏼</span>
                  </div>
                  <span>Abendkasse</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sp-12 row-sp-12 ticketButton">
            <a @click="scrollToTickets()">Jetzt Ticket sichern</a>
          </div>
        </div>
      </section>
      <section id="eventDetailsContent">
        <div style="text-transform: uppercase; font-size: 1.4em; padding-left: 16px;">
          <h3>Event Details</h3>
        </div>
        <div class="pad-16 color-white br-10 eventDetailsBox">
          <p v-html="nl2br(eventDetails.description)" />
        </div>
        <div class="pad-16 t-center" id="eventLabels">
          <eventLabels v-for="elitems in eventLabels" :event-labels="elitems" :key="elitems.id" />
        </div>
      </section>
      <section id="joinCTA">
        <div class="pad-16 t-center">
          <h5 class="color-white f-bold">
            {{ isAttendees ? 'Du nimmst teil!' : 'Bereit für eine verrückte Nacht ?' }}
          </h5>
          <button v-if="!isAttendees" class="button bgcolor-blue color-white" @click="attendee()">
            Jetzt Teilnehmen
          </button>
          <button v-else class="button bgcolor-second color-white" @click="attendee()">
            Absagen
          </button>
          <button class="button bgcolor-second color-white sharebutton" @click="openShareMenu()" v-if="shareMenu">
            <img src="assets/images/icons/share-white.svg" class="width-20">
          </button>
        </div>
      </section>
      <section v-if="eventDetails.muttizettel" class="bgcolor-second br-10 pad-24" style="margin-bottom: 16px;">
        <h4 class="f-bold color-white t-center f-normal" style="font-size: 1.3em;">
          Noch keine 18? Kein Problem:
        </h4>
        <div class="pad-4"></div>
        <router-link :to="'/muttizettel?event='+eventDetails.id" class="button bgcolor-blue fluid color-white t-center" style="box-sizing: border-box;">
          Muttizettel ausfüllen
        </router-link>
      </section>
      <section class="bgcolor-second br-10" id="ticketSale" v-if="parseInt(eventDetails.ticketSale) === 1">
        <div class="pad-16" ref="tickets">
          <h4 class="f-bold color-white t-center">
            Jetzt Tickets sichern!
          </h4>
          <div>
            <div class="grid-12" v-if="eventDetails.ticketSaleLink != null && eventDetails.ticketSaleLink !== 'null'">
              <div class="col-sp-6 row-sp-12">
                <p>Tickets über externen Anbieter kaufen:</p>
              </div>
              <div class="col-sp-6 row-sp-12 t-right">
                <a :href="eventDetails.ticketSaleLink" target="_blank" class="button bgcolor-blue color-white">Ticket Shop öffnen</a>
              </div>
            </div>
            <div v-else>
              <ticketItems v-for="titems in ticketItems" :ticket-items="titems" :key="titems.id" />
              <p v-if="!ticketItems.length" class="t-center">
                Aktuell sind keine Tickets verfügbar
              </p>
            </div>
          </div>
        </div>
      </section>
      <div class="pad-32" />
      <section class="bgcolor-second br-10" id="ticketSale" v-if="lounges != ''">
        <div class="pad-16">
          <h4 class="f-bold color-white t-center">
            Jetzt Lounges sichern!
          </h4>
          <div>
            <div>
              <lounge v-for="lounge in lounges" :lounge="lounge" :key="lounge.id" />
            </div>
          </div>
        </div>
      </section>
      <section id="otherEvents">
        <div>
          <h4 class="t-center">
            Weitere <span class="color-blue">Events</span>
            die dir gefallen könnten
          </h4>
        </div>
        <section id="searchResults">
          <events v-for="event in events" :event="event" :key="event.id" />
          <p class="t-center color-white" v-if="events.length == 0">
            Aktuell gibt es keine weiteren geplanten Events von diesem Veranstalter.
          </p>
        </section>
      </section>
    </main>
    <transition name="modal" id="filterModal">
      <div class="vueModal" v-if="filterModal">
        <div class="vueModalOuter" @click="filterModal = false;" />
        <div class="vueModalInner bottom bgcolor-second">
          <div class="t-center pad-8">
            <h2>Events Filtern</h2>
            <button @click="filterModal = false;" class="button fluid bgcolor-blue color-white">
              Okay
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import events from "@/components/home/event";
import eventLabels from "@/components/eventDetails/label";
import ticketItems from "@/components/eventDetails/ticket";
import {aSyncData, nl2br} from "@/modules/main";
import Lounge from "@/components/eventDetails/lounge";

export default {
  name: "EventDetails",
  title: "EventDetails",
  components: {
    Lounge,
    'events': events,
    eventLabels,
    ticketItems,
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      events: [],
      eventDetails: {},
      eventLabels: '',
      startTime: '',
      startDate: '',
      startDay: '',
      isAttendees: false,
      ticketItems: [],
      lounges: '',
      filterModal: false,
      cheapestPrice: '',
      vvkEnd: '',
      shareMenu: false
    }
  },
  computed: {
  },
  mounted() {
    this.config.loading = false
    this.loadDetails()
    this.getCheapestTicket()
    this.checkShareFunction()
  },
  methods: {
    checkShareFunction() {
      if (navigator.share) {
        this.shareMenu = true;
      } else {
        this.shareMenu = false;
      }
    },
    openShareMenu() {
      if (navigator.share) {
        navigator.share({
          title: this.eventDetails.title,
          url: window.location.href
        })
      }
    },
    loadDetails(){
      aSyncData('eventdetails/getDetails', {eventId: this.$route.params.id})
          .then(r => {
            if(r.status === 250){
              this.events = r.data.events
              this.eventDetails = r.data.eventDetails
              this.ticketItems = r.data.ticketItems
              this.eventLabels = r.data.eventLabels
              this.lounges = r.data.lounges
              this.isAttendees = r.data.isAttendees
              const jsTimestamp = r.data.eventDetails.startTime.split(' ')
              const jsDate = jsTimestamp[0].split('-')
              const jsTime = jsTimestamp[1].split(':')
              this.startTime = jsTime[0] + ':' + jsTime[1]
              this.startDate = jsDate[2] + '.' + jsDate[1]
              const d = new Date(r.data.eventDetails.startTime);
              switch(d.getDay()) {
                case 0:
                  this.startDay = 'Sonntag'
                  break;
                case 1:
                  this.startDay = 'Montag'
                  break;
                case 2:
                  this.startDay = 'Dienstag'
                  break;
                case 3:
                  this.startDay = 'Mittwoch'
                  break;
                case 4:
                  this.startDay = 'Donnerstag'
                  break;
                case 5:
                  this.startDay = 'Freitag'
                  break;
                case 6:
                  this.startDay = 'Samstag'
                  break;
              }

            }else{
              this.$router.push('/Home')
            }
          })
    },
    attendee() {
      if(!this.auth.isAuth) this.$router.push('/login?redirect=' + this.$route.path)
      const _this = this
      const fD = new FormData()
      fD.append('eventId', this.$route.params.id)
      fD.append('isAttendees', JSON.stringify(this.isAttendees))
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('eventdetails/attendee', fD)
          .then(r => {
            if(r.status === 250) _this.isAttendees = !_this.isAttendees
          })
    },
    nl2br(str){
      return nl2br(str)
    },
    scrollToTickets(){
      this.$refs['tickets'].scrollIntoView({behavior: "smooth"})

      !function (w, d, t) {
        w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};

        ttq.load('CGCR9LRC77U734TI19L0');
        ttq.page();
      }(window, document, 'ttq');
    },
    getCheapestTicket(){
      const _this = this
      const fD = new FormData()
      fD.append('eventId', this.$route.params.id)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('eventdetails/getCheapestTicket', fD)
          .then(r => {
            if(r.status === 250)
              _this.cheapestPrice = r.data.price
              const jsTimestamp = r.data.sellEnd.split(' ')
              const jsTime = jsTimestamp[1].split(':')
              _this.vvkEnd = jsTime[0] + ':' + jsTime[1]
          })
    }
  }
}
</script>

<style>
main{
  padding-top: 80px;
}
section#searchResults{
  padding: 16px;
  margin-top: 32px;
}
section#eventDetailsHeader{
  padding: 16px;
}
section#eventDetailsHeader .label{
  margin-right: 16px;
  margin-bottom: -32px;
  position: relative;
  z-index: 3;
  padding: 10px 8px;
  text-transform: none;
}
section#eventDetailsHeader img.titleImage{
  width: 100%;
  max-width: 100%;
  border-radius: 10px;
  border: 2px solid #ffffff;
  margin-bottom: 10px;
  margin-top: -8px;
}
section#eventDetailsHeader h4{
  font-size: 1.6em;
  font-family: 'Helvetica';
  font-weight: 600;
  color: rgba(255,255,255,1);
  margin-bottom: 0px;
}
section#eventDetailsHeader .meta{
  padding-top: 4px;
  opacity: 0.6;
}
section#eventDetailsHeader .meta ul{
  padding: 0;
  margin: 0;
}
section#eventDetailsHeader .meta ul li{
  padding: 0;
  margin: 0;
  display: inline;
  margin-right: 12px;
  font-weight: 200;
  font-size: 0.8em;
}
section#eventDetailsHeader .meta ul li img{
vertical-align: -4px;
}

section#eventDetailsHeader .detailswrapper{
  column-gap: 12px;
  margin-bottom: 30px;
}

section#eventDetailsHeader .detailbox{
  background-color: #1E1E1E;
  padding: 16px;
  border-radius: 10px;
}

section#eventDetailsHeader .detailboxFlex{
  display: flex;
  align-items: center;
  background-color: #1E1E1E;
  padding: 4px;
  border-radius: 10px;
  margin-top: 12px;
  justify-content: center;
  text-align: left !important;
  column-gap: 4px
}

section#eventDetailsHeader .detailboxFlex p{
  font-size: 0.9em;
}

section#eventDetailsHeader .ticketButton{
  margin: 12px 0;
}

section#eventDetailsHeader .ticketButton a{
  text-transform: none;
  padding: 12px 32px;
  font-weight: 500;
  font-size: 0.8em;
  margin-right: 16px;
  vertical-align: top;
  background-color: #2068ff!important;
  color: white;
  border: none;
  border-radius: 8px;
  display: inline-block;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
}

section#eventDetailsHeader .clubzettel {
  margin: 12px 0 0;
}

section#eventDetailsHeader .clubzettel .grid-12{
  column-gap: 12px;
}

section#eventDetailsHeader .clubzettel .flex{
  align-items: center;
  justify-content: center;
  column-gap: 12px;
}

section#eventDetailsHeader .clubzettel .box{
  padding: 16px;
  background-color: #1E1E1E;
  border-radius: 10px;
}



section#eventDetailsHeader h4{
  font-size: 1em;
  font-weight: 600;
}

section#eventDetailsHeader h5{
  font-size: 0.8em;
  padding-top: 6px;
  font-weight: 400;
}

section#eventDetailsContent{
  padding-top: 0;
  margin-top: -16px;
}
section#eventDetailsContent h5{
  font-size: 1.2em;
}
section#eventDetailsContent p{
  margin-top: 8px;
  font-size: 0.95em;
  font-family: 'Helvetica';
  font-weight: 300;
  color: white;
}

section#eventDetailsContent .eventDetailsBox{
  background-color: var(--second-bg-color);
  margin: 16px;
}

section#eventDetailsContent #eventLabels span{
  cursor: pointer;
  padding: 8px 10px;
  border-radius: 16px;
  font-weight: 400;
  font-size: 0.9em;
  font-family: 'Helvetica';
  margin-right: 8px;
  display: inline-block;
  margin-bottom: 8px;
}
section#joinCTA{
  margin-bottom: 32px;
}
section#joinCTA h5{
  margin-bottom: 16px;
  font-size: 1.1em;
  font-weight: 500;
}
section#joinCTA button:not(.sharebutton){
  text-transform: none;
  padding: 12px 32px;
  font-weight: 500;
  font-size: 0.8em;
  margin-right: 16px;
  vertical-align: top;

}
section#joinCTA button.sharebutton{
  padding: 8px 10px;
}
section#ticketSale h4{
  font-size: 1.3em;
  margin-top: 16px;
  margin-bottom: 16px;
  font-weight: 500;
}
section#ticketSale button{
  text-transform: none;
}
section#ticketSale p{
  color: rgba(255,255,255,0.6);
}
section#eventHoster h5{
  font-weight: 300;
  margin-top: 32px;
  font-size: 0.8em;
  margin-bottom: 8px;
}
section#otherEvents h4{
  font-size: 1.5em;
  font-weight: 300;
  margin-top: 32px;
}
section#otherEvents h4 span{
  font-weight: 700;
}


</style>