<template>
  <div style="margin-top: 200px">
    <loader size="small" v-if="loading" />
    <div style="overflow: auto" v-else-if="finished" class="content-500">
      <h2 style="text-align: center; margin-bottom: 10px;">
        Dein Kauf war erfolgreich!
      </h2>
      <p style="text-align: center; margin-bottom: 20px; font-weight: 600">
        Jetzt downloaden!
      </p>
      <div class="flex downloadButton-wrapper">
        <button class="button-primary" v-if="tickets">
          <download-p-d-f :link="item.encrypted" v-for="item in tickets" :key="item.name" name="Tickets" :file-name="item.name" target="" file-type="ticket" />
        </button>
        <button class="button-primary">
          <download-p-d-f :link="invoice.encrypted" name="Rechnung" target="" file-type="invoice" />
        </button>
      </div>
      <div v-if="tickets" class="pad-16">
        <div class="bgcolor-black br-10 pad-16 color-white t-center">
          <h3 class="f-bold" style="font-size: 1.4em">Kein Ticket erhalten?</h3>
          <span style="font-size: 1.8em;">👇🏻</span>
          <div class="pad-4"></div>
          <router-link to="/faq" class="button-primary">Hier klicken und nochmal Downloaden</router-link>
        </div>
      </div>
      <div v-if="config.getMobileOperatingSystem === 'iOS' && passBooks">
        <hr class="separator">
        <h3 style="text-align: center">
          Add to Wallet
        </h3>
        <div style="text-align: center; margin-top: 20px; margin-bottom: 12px;">
          <add-to-wallet :file="item" v-for="item in passBooks" :key="item.name" target="" file-type="passBook" />
        </div>
      </div>
    </div>
    <div v-else style="text-align: center">
      Da ging was schief.
    </div>
    <!-- Meta Pixel Code -->
    <noscript><img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=619966436269049&ev=PageView&noscript=1"/></noscript>
    <!-- End Meta Pixel Code -->
  </div>
</template>

<script>

import {aSyncData} from "@/modules/main";
import Loader from "@/components/basic/loader";
import DownloadPDF from "@/components/payment/downloadPDF";
import AddToWallet from "@/components/payment/addToWallet";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";

export default {
  name: "checkoutFinishedView",
  components: {DownloadPDF, AddToWallet, Loader},
  props: {
    cipher: {
      type: String
    }
  },
  setup() {
    const config = configStore()
    const user = userStore()

    return {config, user}
  },
  data(){
    return{
      loading: true,
      invoice: {},
      tickets: {},
      passBooks: [],
      finished: false,
      interval: null
    }
  },
  mounted() {
    this.getFiles()
  },
  methods: {
    getFiles(){
      const _this = this;
      aSyncData('checkout/getData', {cipher: this.cipher})
          .then(r => {
            console.log(r)
            if(r.status === 250){
              this.finished = true
              this.invoice = r.data.invoice
              this.tickets = r.data.tickets
              this.passBooks = r.data.passBooks
              this.user.credits = r.data.credits
              this.loading = false

              /* Facebook Pixel */
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                  'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '619966436269049');
              fbq('track', 'PageView');
              /* TikTok Pixel */
              !function (w, d, t) {
                w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};

                ttq.load('CGCR9LRC77U734TI19L0');
                ttq.page();
              }(window, document, 'ttq');


            }else if(r.status === 260){
              this.loading = false
            }else if(r.status === 251){
              setTimeout(function(){
                _this.getFiles();
              }, 2000);
            }
          })
          .catch(e => {console.error(e)})
    }
  }
}
</script>

<style scoped>

  .flex {
    display: flex;
  }
  .downloadButton-wrapper{
    justify-content: center;
    align-items: center;
    row-gap: 8px;
    flex-wrap: wrap;
  }

  .button-primary{
    width: 70%;
    border: none;
  }

  .separator{
    opacity: 0.1;
    margin: 16px 0;
  }
</style>